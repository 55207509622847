

























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, State, namespace } from 'vuex-class';
import Container from '@/components/Container.vue';
import Receipt from './components/Receipt.vue';
import { RootState } from '@/store/store';
import { Order } from '@/store/modules/order/order.types';
import { get as _get } from 'lodash';
import { NotificationProgrammatic as Notification } from 'buefy';
import { moneyParser } from '../../utils/parser.util';
import { Product } from '@/store/modules/application/types/application.types';
import { JAAS_APP } from '../../jbi-shared/types/jaas-app.types';

@Component({
  components: {
    Receipt,
    Container
  }
})
export default class OrderPage extends Vue {
  @Action('order/orderReset') public orderReset!: () => Promise<void>;

  @Action('order/getOrder') public getOrder!: (id: number) => Promise<void>;

  @Action('order/updateOrder')
  public updateOrder!: ({
    id,
    resultKey
  }: {
    id: number;
    resultKey: string | string[];
  }) => Promise<void>;

  @Action('application/getProduct')
  public getProduct!: ({ productId }: { productId: number }) => Promise<void>;

  @State(({ order }: RootState) => order.order)
  public order!: Order;

  @State(({ application }: RootState) => application.product)
  public product!: Product;

  get applicationUrl() {
    if (this.product) {
      const redirectURL =
        this.product.applicationId === JAAS_APP.paces
          ? process.env.VUE_APP_PACES_FRONTEND_URL
          : this.product.applicationId === JAAS_APP.sumari
          ? process.env.VUE_APP_SUMARI_URL
          : 'https://google.com';
      return redirectURL;
    }
  }

  get bPointPayload() {
    const streetAddress = this.order.streetAddress
      ? this.order.streetAddress.replace(/,\s*$/, '') + ', '
      : '';
    const city = this.order.city ? this.order.city + ', ' : '';
    const country = this.order.country ? this.order.country : '';

    const billingAddress = country
      ? `${streetAddress} ${city} ${country}`
      : 'N/A';

    const amount = this.order.paymentAmount / 100;
    const gstAmount = +(this.order.gstAmount / 100).toFixed(2);
    const gstPercentage =
      gstAmount > 0
        ? `(${Math.round((amount / (amount - gstAmount) - 1) * 100)}%)`
        : '';

    // TODO: amount and gst should return from server
    // prettier-ignore
    return {
      productName: this.order.productName,
      price: moneyParser({ value: this.order.paymentAmount - this.order.gstAmount }),
      gst: gstAmount > 0 ? moneyParser({ value: this.order.gstAmount}) : 'N/A',
      amount: moneyParser({ value: this.order.paymentAmount}),
      cardHolderName: _get(this.order.paymentTxnResponsePayload, '.CardDetails.CardHolderName') || 'N/A',
      cardNumber: _get(this.order.paymentTxnResponsePayload, 'CardDetails.MaskedCardNumber') || 'N/A',
      receiptNumber: _get(this.order.paymentTxnResponsePayload, 'ReceiptNumber'),
      merchantNumber: _get(this.order.paymentTxnResponsePayload, 'MerchantNumber') || '-',
      billingAddress,
      gstPercentage,
    };
  }

  get orderId() {
    return +this.$route.params.id;
  }

  public async paymentCallback() {
    const {
      ResultKey: resultKey,
      ResponseText: responseText,
      ResponseCode: responseCode
    } = this.$route.query;

    if (resultKey) {
      // TODO: trigger callback
      await this.updateOrder({
        id: this.orderId,
        resultKey: resultKey as string
      });
    }
  }

  get urlQuery() {
    return this.$route.query;
  }

  public async mounted() {
    await this.orderReset();

    await this.paymentCallback();

    await this.getOrder(this.orderId);

    await this.getProduct({ productId: this.order.productId });

    if (!this.order) {
      return this.$router.replace({ name: 'page-not-found' });
    }

    if (this.order.paymentStatus === 'FAILED') {
      return this.$router.replace({ name: 'Orders' }, () => {
        Notification.open({
          queue: true,
          type: 'is-danger',
          position: 'is-top',
          duration: 10000,
          message: `<b>Payment Failed</b>. Please try again later.`
        });
      });
    }
  }
}
