




























































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import dayjs from 'dayjs';

@Component({
  components: {}
})
export default class Receipt extends Vue {
  get dayjs() {
    return dayjs;
  }

  @Prop({ default: '-' }) public orderNumber!: string;
  @Prop({ default: new Date() }) public orderDate!: string;
  @Prop({ default: '-' }) public receiptNumber!: string;
  @Prop({ default: '-' }) public merchantNumber!: string;
  @Prop({ default: '-' }) public productName!: string;
  @Prop({ default: '-' }) public price!: string;
  @Prop({ default: '0.00' }) public gst!: string;
  @Prop({ default: '$AUD' }) public currency!: string;
  @Prop({ default: '-' }) public amount!: string;
  @Prop({ default: 'N/A' }) public cardHolderName!: string;
  @Prop({ default: 'N/A' }) public cardNumber!: string;
  @Prop({ default: 'N/A' }) public billingAddress!: string;
  @Prop({ default: '' }) public gstPercentage!: string;
}
